import React from 'react';

interface FeedbackTextboxProps {
  feedback: string;
  feedbackName: string;
  handleSubmit: (e: React.ChangeEvent<HTMLFormElement>) => void;
  score: string;
  setFeedback: (e: string) => void;
  text: string;
}

const FeedbackTextbox = ({
  handleSubmit,
  feedback,
  feedbackName,
  score,
  setFeedback,
  text,
}: FeedbackTextboxProps): JSX.Element => {
  const feedbackGAName = () => {
    return feedback ? `${feedbackName} feedback` : `${feedbackName} score`;
  };

  return (
    <div className="sm:ch-bt--1 ch-bc--grey-3 sm:ch-pt--3 ch-mt--2 sm:ch-mt--3">
      <p className="ch-mb--3 ch-width--12 sm:ch-width--6">{text}</p>
      <form
        className="ch-display--flex ch-flex-flow--column-wrap ch-width--12 sm:ch-width--6"
        onSubmit={handleSubmit}
      >
        <textarea
          className="feedback__comment ch-ba--1 ch-bc--grey-4 ch-mb--3"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setFeedback(e.target.value)
          }
          placeholder="Your comment"
          rows={8}
          value={feedback}
        />
        <input
          className="ch-btn sm:ch-width--4 ch-btn--success"
          data-feedback-message={`[${score}] ${feedback}`}
          data-feedback-name={feedbackGAName()}
          data-feedback-score={score}
          data-gtm-track="feedback"
          type="submit"
          value="Send"
        />
      </form>
    </div>
  );
};

export default FeedbackTextbox;
