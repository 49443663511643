import React, { useState, useEffect, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from '@lib/hooks';
import { camelCaseKeys, updatePageTitle } from '@lib/utils';
import Analytics from '@lib/analytics';
import VehicleSearchPage from './vehicleSearchPage';
import { additionalFilterChangesFor } from './helpers/vehicleSearchHelpers';
import useShowMore from './hooks/useShowMore';
import { useTagVisibilityToggleState } from './hooks/useTagVisibilityToggle';
import localStorageAvailable from '@helpers/localStorage';
import useTrackAppliedFiltersForGA from '@javascript/hooks/analytics/useTrackAppliedFiltersForGA';
import { createStockAlertUrl } from '@javascript/stock_alerts/helpers';

export const MAKE_MODEL_KEY = 'makeModel';
export const HeaderFilterContext = createContext(null);

const VehicleSearchContainer = ({
  availableOptions,
  banner,
  clickAndCollectDetails,
  count,
  defaultSearchFilters,
  experiments,
  initialAppliedFilters,
  isRealSale,
  isRewardsWeekSale,
  pagination,
  searchResults,
  searchTitle,
  showClickAndCollectOptions,
  stockChipData,
  title,
  virtualPagePath,
  authenticityToken,
}) => {
  const headerFilterState = useTagVisibilityToggleState();
  const stockAlertUrl = createStockAlertUrl(initialAppliedFilters);

  const blankFilters = { ...defaultSearchFilters };

  const [loading, setLoading] = useState(false);

  const [vehicleSearch, setVehicleSearch] = useState({
    appliedFilters: initialAppliedFilters,
    result: {
      banner,
      clickAndCollectDetails,
      count,
      pagination,
      searchResults,
      searchTitle,
      showClickAndCollectOptions,
      title,
      virtualPagePath,
      stockChipData,
    },
    hasSearched: false,
  });

  const [hasSearched, setHasSearched] = useState(false);
  const [shouldResetScroll, setShouldResetScroll] = useState(false);
  const { trackAppliedFiltersForGA } = useTrackAppliedFiltersForGA();

  const hasComponentInitialized = usePrevious(true);

  useEffect(() => {
    if (!hasComponentInitialized) return;
    updatePageTitle(vehicleSearch.result.title);
  }, [vehicleSearch.result]);

  useEffect(() => {
    if (localStorageAvailable()) {
      localStorage.setItem('lastSearch', stockAlertUrl);
    }
  }, []);

  const { determineResult } = useShowMore(
    vehicleSearch,
    setVehicleSearch,
    hasSearched,
    setHasSearched
  );

  const updateFilterSearch = (filtersSearch) => {
    const {
      result: { url },
    } = filtersSearch;

    const newVehicleSearch = {
      ...vehicleSearch,
      ...filtersSearch,
      hasSearched: true,
    };

    window.history && window.history.pushState(newVehicleSearch, '', url);
    localStorage.setItem('lastSearch', url);
    setVehicleSearch(newVehicleSearch);
    setHasSearched(true);
  };

  const requestSearchResults = async (changedFilters) => {
    try {
      const requestFilters = {
        ...vehicleSearch.appliedFilters,
        ...additionalFilterChangesFor(
          camelCaseKeys(changedFilters),
          vehicleSearch
        ),
      };
      /* eslint-disable-next-line no-unused-vars */
      const { resetLocation, resetDeposit, ...appliedFilters } = requestFilters;
      setVehicleSearch({
        ...vehicleSearch,
        appliedFilters,
      }); // Update filter state in UI before fetching
      Analytics.dataPush('criteria', trackAppliedFiltersForGA(appliedFilters));
      setLoading(true);

      const result = await determineResult(
        changedFilters['page'],
        requestFilters
      );

      setShouldResetScroll(!('page' in changedFilters));

      updateFilterSearch({
        appliedFilters,
        result: result,
      });
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const resetFilters = useCallback(() => {
    Analytics.trackEvent('interaction', {
      action: 'Search filters',
      label: 'Reset filters',
    });
    requestSearchResults(blankFilters, true);
  }, []);

  return (
    <HeaderFilterContext.Provider value={headerFilterState}>
      <VehicleSearchPage
        availableOptions={availableOptions}
        blankFilters={blankFilters}
        experiments={experiments}
        isRealSale={isRealSale}
        isRewardsWeekSale={isRewardsWeekSale}
        onFilterCriteriaUpdate={requestSearchResults}
        resetFilters={resetFilters}
        {...vehicleSearch}
        authenticityToken={authenticityToken}
        loading={loading}
        shouldResetScroll={shouldResetScroll}
      />
    </HeaderFilterContext.Provider>
  );
};

export default VehicleSearchContainer;

VehicleSearchContainer.propTypes = {
  authenticityToken: PropTypes.string,
  availableOptions: PropTypes.object,
  banner: PropTypes.bool,
  clickAndCollectDetails: PropTypes.object,
  count: PropTypes.number,
  defaultSearchFilters: PropTypes.object,
  experiments: PropTypes.object,
  hasSearched: PropTypes.bool,
  initialAppliedFilters: PropTypes.object,
  isRealSale: PropTypes.bool,
  isRewardsWeekSale: PropTypes.bool,
  pagination: PropTypes.object,
  searchResults: PropTypes.array,
  searchTitle: PropTypes.string,
  showClickAndCollectOptions: PropTypes.bool,
  stockChipData: PropTypes.array,
  title: PropTypes.string,
  virtualPagePath: PropTypes.string,
};
