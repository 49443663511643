import { useState } from 'react';
import FeedbackQuestion from './feedbackQuestion';
import FeedbackSuccess from './feedbackSuccess';
import { setCookie } from './helpers/feedbackCookieHelper';

interface FeedbackContainerProps {
  heading: string;
  name: string;
  question: string;
}

const FeedbackContainer = ({
  heading,
  name,
  question,
}: FeedbackContainerProps) => {
  const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);

  const onComplete = (): void => {
    setQuestionAnswered(true);
    setCookie(name);
  };

  return (
    <div className="ch-card ch-mb--2">
      <div className="ch-bg--grey-1 ch-ph--2 ch-pv--4 sm:ch-pa--4">
        {questionAnswered ? (
          <FeedbackSuccess
            successHeading="Thank You"
            successMessage={`Your feedback has been sent and will help us improve ${
              name === 'Search Experience Feedback'
                ? 'our search experience'
                : 'ArnoldClark.com'
            }`}
          />
        ) : (
          <FeedbackQuestion
            feedbackName={name}
            heading={heading}
            onComplete={onComplete}
            question={question}
          />
        )}
      </div>
    </div>
  );
};

export default FeedbackContainer;
