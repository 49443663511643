import Modal from '../modal';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

interface RewardsWeekBannerProps {
  saving?: number;
  variant?: 'product-predefined' | 'serps-predefined' | 'serps' | 'product';
}

const RewardsWeekBanner = ({ saving, variant }: RewardsWeekBannerProps) => {
  const openModal = () =>
    EventEmitter.emit(EVENT_TYPES.openModal, `rewards-week-modal`);
  const closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  const BannerCopy = ({ saving, variant }: RewardsWeekBannerProps) => {
    switch (variant) {
      case 'product-predefined':
        return (
          <>
            Save <span className="ch-color--ac-yellow">£{saving}</span> when you
            buy this car!
          </>
        );

      case 'serps-predefined':
        return (
          <>
            We&apos;ve <span className="ch-color--ac-yellow">discounted</span>{' '}
            these cars for an extra week!
          </>
        );

      default:
        return (
          <>
            Extended! Save up to{' '}
            <span className="ch-color--ac-yellow">£1000</span> on selected cars.
          </>
        );
    }
  };

  return (
    <>
      <div className="ac-promo rewards-week-banner ch-justify-content--between ch-align-items--start">
        <div className="ch-color--white ch-fw--500 ch-fs--3 sm:ch-fs--4">
          <BannerCopy saving={saving} variant={variant} />
          <div className="ch-mt--1 md:ch-display--inline md:ch-mt--0 md:ch-ml--1">
            <button
              className="ac-promo__link ch-color--white ch-fw--400 sm:ch-fs--3 ch-btn ch-btn--link ch-pa--0"
              onClick={openModal}
              type="button"
            >
              Find out more
            </button>
          </div>
        </div>
        <img
          alt="Rewards Week"
          className="ac-promo__image ch-ml--2"
          height="40"
          width="123"
        />
      </div>
      <Modal hideClose id="rewards-week-modal">
        <div className="ch-modal rewards-week-modal">
          <div className="modal__header rewards-week-modal__header">
            <button
              className="ch-modal__close"
              onClick={closeModal}
              type="button"
            >
              <span className="ch-reader">Close</span>
            </button>
            <div className="ch-display--flex ch-flex-direction--column ch-align-items--center">
              <img
                alt="Rewards Week"
                className="rewards-week-modal__image ch-mb--2"
                height="60"
                width="145"
              />
              <span className="ch-chip ch-chip--grey-1 ch-fw--600">
                EXTENDED UNTIL 23RD MARCH
              </span>
            </div>
          </div>
          <div className="ch-text--center ch-ph--5 ch-pv--3 ch-bg--white">
            <p className="ch-fs--4 ch-fw--600">
              Save up to £1000 on selected cars.
            </p>
            <p className="ch-fs--2">
              Limited stock - these deals are going fast!
            </p>
            <a
              className="ch-btn ch-btn--success ch-btn--sm ch-mb--2"
              href="/vehicles?keywords=Rewards+Week+Deals"
            >
              Shop the deals
            </a>
            <p>
              <a className="ch-fw--500" href="/rewards/rewards-week">
                Find out more
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RewardsWeekBanner;
