import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import LogoutModal from './logoutModal';
import Modal from '@components/application/modal';
import BackButton from '../application/backButton';
import Auth from '@javascript/lib/auth';
import Loading from './loading';
import useUserEmail from '@javascript/lib/hooks/useUserEmail';
import { getGreeting } from './helpers';
import { WithExperiments } from '@javascript/types/Experiment';

const MyAccount = (props: Partial<WithExperiments>) => {
  const openLogoutModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, `logout`);
  };

  const { userEmail, loading } = useUserEmail();

  if (loading && !userEmail) {
    return <Loading />;
  }

  if (!userEmail) {
    new Auth()
      .initializeAuthClient(window.authOptions)
      .then((client) => client.login());
    return <Loading />;
  }

  return (
    <>
      <div className="ch-container ch-ph--3 ch-mt--3">
        <BackButton />
        <div className="ac-account">
          <div className="ch-fs--3 ch-mb--4">
            <p className="ch-fw--500 ch-mb--0">
              {getGreeting(new Date().getHours())}
            </p>
            <p className="ch-fw--400 ch-mb--0">{userEmail}</p>
          </div>

          {props.experiments?.accountOrderLink && (
            <div className="ch-ba--1 ch-rounded--md ch-bc--white ch-bg--white ch-ph--3 ch-mv--3 ch-display--flex ch-flex-flow--column-nowrap">
              <a
                className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
                data-gtm-track="interaction"
                data-gtm-track-action="Clicks link"
                data-gtm-track-label="Your Order - My Account"
                href="https://www.arnoldclark.com/customer-dashboard"
              >
                Your order
              </a>
            </div>
          )}

          <div className="ch-ba--1 ch-rounded--md ch-bc--white ch-bg--white ch-ph--3 ch-mv--3 ch-display--flex ch-flex-flow--column-nowrap">
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              data-gtm-track="interaction"
              data-gtm-track-action="Clicks link"
              data-gtm-track-label="Account help - My Account"
              href="/help-topics"
            >
              Account help
            </a>
            <hr className="ch-bg--grey-3"></hr>
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              href="/cookies"
            >
              Privacy policy
            </a>
            <hr className="ch-bg--grey-3"></hr>
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              href="https://forms.office.com/e/66gdPZJABz"
            >
              Account feedback
            </a>
          </div>
          <button
            className="ch-ba--1 ch-rounded--md ch-width--12 ch-bc--white ch-bg--white ch-pv--3 ch-ph--2 ch-mb--3 ch-fs--3 ch-fw--500 ch-text--left log-out"
            data-gtm-track="click_cta"
            data-gtm-track-label="Logout"
            onClick={openLogoutModal}
            type="button"
          >
            Log out
          </button>
        </div>
      </div>
      <Modal hideClose id="logout">
        <LogoutModal />
      </Modal>
    </>
  );
};

export default MyAccount;
