import React, { useState, useEffect } from 'react';
import FeedbackTextbox from './feedbackTextbox';

const FEEDBACK_SCHEMA = {
  sad: 1,
  'slightly-sad': 2,
  neutral: 3,
  'slightly-happy': 4,
  happy: 5,
};

type FeedbackType = keyof typeof FEEDBACK_SCHEMA;

const commentFieldPrompt = {
  '1': 'Sorry! We’d love to fix that. Tell us what’s going wrong.',
  '2': 'We’d love to hear more. Tell us what’s going wrong.',
  '3': 'We’d love to fix that. Tell us what we can do to improve.',
  '4': 'Thanks. Can you tell us more?',
  '5': 'We love good news. Can you tell us more?',
};

const serpsCommmentFieldPrompt = {
  '1': 'We’d love to fix that. Can you tell us what went wrong?',
  '2': 'We’d love to fix that. Can you tell us what went wrong?',
  '3': 'We’d love to fix that. Can you tell us what we can do to improve the search experience?',
  '4': 'We love good news. Can you tell us more about your experience?',
  '5': 'We love good news. Can you tell us more about your experience?',
};

type Props = {
  feedbackName: string;
  heading: string;
  onComplete: (args: { feedback: string; score: string }) => void;
  question: string;
};

const FeedbackQuestion = ({
  feedbackName,
  heading,
  onComplete,
  question,
}: Props): JSX.Element => {
  const [score, setScore] = useState<keyof typeof commentFieldPrompt | null>(
    null
  );
  const [feedback, setFeedback] = useState<string>('');
  const [feedbackRequired, setFeedbackRequired] = useState<boolean>(false);

  useEffect(() => {
    if (!score) return;
    setFeedbackRequired(true);
  }, [score]);

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    setScore(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onComplete({ feedback, score: score! });
  };

  const renderRadios = () => {
    return Object.keys(FEEDBACK_SCHEMA).map((key) => (
      <React.Fragment key={key}>
        <input
          className="ch-radio"
          id={key}
          name="score"
          type="radio"
          value={FEEDBACK_SCHEMA[key as FeedbackType]}
        />
        <label
          className={`ch-hand feedback__button feedback__button--${key}`}
          htmlFor={key}
        >
          <span className="ch-reader">{key}</span>
        </label>
      </React.Fragment>
    ));
  };

  const commentFieldText = () => {
    if (score === null) {
      return '';
    }
    return feedbackName === 'Search Experience Feedback'
      ? serpsCommmentFieldPrompt[score]
      : commentFieldPrompt[score];
  };

  return (
    <>
      <div className="ch-display--flex ch-flex-flow--column-wrap sm:ch-flex-flow--row-nowrap sm:ch-align-items--center">
        <div className="ch-flex--auto">
          <h3>{heading}</h3>
          <p className="sm:ch-mb--0">{question}</p>
        </div>
        <form
          className="ch-display--flex ch-flex--none ch-justify-content--around sm:ch-width--4 md:ch-width--3"
          onChange={handleChange}
        >
          {renderRadios()}
        </form>
      </div>
      {feedbackRequired && (
        <FeedbackTextbox
          feedback={feedback}
          feedbackName={feedbackName}
          handleSubmit={handleSubmit}
          score={score!}
          setFeedback={setFeedback}
          text={commentFieldText()}
        />
      )}
    </>
  );
};

export default FeedbackQuestion;
