import { buildFiltersQueryString } from '@javascript/lib/requests/vehicleSearch';
import { AppliedFilters } from '@javascript/types/FilterSearch';
import localStorageAvailable from '@javascript/helpers/localStorage';
import {
  selectedMakeToString,
  selectedLocationToString,
  selectedPriceToString,
  selectedToUpToString,
  selectedValueToString,
  selectedEngineSizeToString,
  selectedMPGToString,
  selectedKeywordsToString,
  selectedRoadTaxToString,
  selectedMakeModelsToString,
  selectedMakeModelsTrimsToString,
} from '@components/search_filters/helpers/selectedOptionsDisplay';
import { Frequency, StockAlert } from '@javascript/types/StockAlerts';
import { capitalizeFirstLetter, hasValue } from '@javascript/lib/utils';

const STOCK_ALERT_SUMMARY_ORDER = [
  // the order of these determines the order of the stock alert summary cards
  // eslint-disable-next-line sort-keys
  'makeModel',
  'distance',
  'location',
  'maxPrice',
  'minPrice',
  'age',
  'bodyType',
  'searchType',
  'driveTrain',
  'colour',
  'doors',
  'maxEngineSize',
  'minEngineSize',
  'mpg',
  'fuelType',
  'transmission',
  'keywords',
  'features',
  'mileage',
  'roadtaxCost',
  'seats',
  'frequency',
] as Array<keyof StockAlert>;

export const createStockAlertUrl = (
  appliedFilters: Partial<AppliedFilters> | Partial<StockAlert>
) => {
  return `/stock-alerts/new?${buildFiltersQueryString(appliedFilters)}`;
};

export const getStockAlertUrlFromStorage = (fallbackUrl: string): string => {
  let stockAlertUrl = fallbackUrl;

  if (localStorageAvailable()) {
    stockAlertUrl =
      localStorage
        .getItem('lastSearch')
        ?.replace('/vehicles', '/stock-alerts/new') || fallbackUrl;
  }

  return stockAlertUrl;
};

export const selectedFrequencyToString = (stockAlert: StockAlert) => {
  const { frequency } = stockAlert;
  if (!frequency) return;
  return frequency === Frequency.DAILY ? 'daily' : 'weekly';
};

export const validStockAlert = (
  stockAlert: Partial<AppliedFilters & StockAlert>
) => {
  const {
    unreservedOnly,
    evCampaign,
    paymentType,
    frequency,
    showClickAndCollectOptions,
    location,
    ...rest
  } = stockAlert;

  return hasValue(rest);
};

export const removeRelatedStockAlertFields = (
  fields: Array<keyof StockAlert>
): Array<keyof StockAlert> => {
  const sanitisedFields = fields;
  const relatedFields: Array<keyof StockAlert>[] = [
    ['maxPrice', 'minPrice'],
    ['minEngineSize', 'maxEngineSize'],
    ['location', 'distance'],
    ['features', 'keywords'],
  ];

  relatedFields.forEach((relatedField) => {
    if (relatedField.every((field) => sanitisedFields.includes(field))) {
      sanitisedFields.splice(sanitisedFields.indexOf(relatedField[0]), 1);
    }
  });

  return sanitisedFields;
};

export const findFieldsWithValues = (
  stockAlert: StockAlert
): Array<keyof StockAlert> => {
  const fieldsWithValues = STOCK_ALERT_SUMMARY_ORDER.filter((key) => {
    return hasValue(stockAlert[key]);
  });

  return removeRelatedStockAlertFields(fieldsWithValues);
};

export const stockAlertsAnalyticsLabel = (stockAlert: StockAlert) => {
  const selectedFieldsBarFrequency = findFieldsWithValues(stockAlert)
    .filter((key) => key !== 'frequency')
    .join(', ');
  const selectedFrequency = selectedFrequencyToString(stockAlert);
  return `${selectedFieldsBarFrequency}, ${selectedFrequency}`;
};

export const stockAlertFormatters: {
  [key: string]: {
    label: string;
    selectedFormatter: (stockAlert: StockAlert) => string | undefined;
  };
} = {
  make: {
    label: 'Makes',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedMakeToString(stockAlert.makeModel),
  },
  model: {
    label: 'Models',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedMakeModelsToString(stockAlert.makeModel),
  },
  trim: {
    label: 'Model variants',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedMakeModelsTrimsToString(stockAlert.makeModel),
  },
  distance: {
    label: 'Location',
    selectedFormatter: (stockalert: StockAlert) =>
      selectedLocationToString(stockalert)?.selectedOptions,
  },
  location: {
    label: 'Location',
    selectedFormatter: (stockalert: StockAlert) =>
      selectedLocationToString(stockalert)?.selectedOptions,
  },
  maxPrice: {
    label: 'Price',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedPriceToString(stockAlert),
  },
  minPrice: {
    label: 'Price',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedPriceToString(stockAlert),
  },
  age: {
    label: 'Age',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedToUpToString(stockAlert.age, 'year%s% old')?.selectedOptions,
  },
  bodyType: {
    label: 'Body types',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'bodyType'),
  },
  searchType: {
    label: 'Car type',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'searchType'),
  },
  driveTrain: {
    label: 'Drive type',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'driveTrain'),
  },
  colour: {
    label: 'Colours',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'colour'),
  },
  doors: {
    label: 'Doors',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'doors'),
  },
  maxEngineSize: {
    label: 'Engine size',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedEngineSizeToString(stockAlert),
  },
  minEngineSize: {
    label: 'Engine size',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedEngineSizeToString(stockAlert),
  },
  mpg: {
    label: 'Fuel consumption/MPG',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedMPGToString(stockAlert),
  },
  fuelType: {
    label: 'Fuel types',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'fuelType'),
  },
  transmission: {
    label: 'Gearbox',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'transmission'),
  },
  keywords: {
    label: 'Keywords',
    selectedFormatter: (stockAlert: StockAlert) => {
      if (!stockAlert.features) stockAlert.features = [];
      return selectedKeywordsToString(stockAlert);
    },
  },
  features: {
    label: 'Keywords',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedKeywordsToString(stockAlert),
  },
  mileage: {
    label: 'Mileage',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedToUpToString(stockAlert.mileage, 'miles')?.selectedOptions,
  },
  roadtaxCost: {
    label: 'Road tax',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedRoadTaxToString(stockAlert),
  },
  seats: {
    label: 'Seats',
    selectedFormatter: (stockAlert: StockAlert) =>
      selectedValueToString(stockAlert, 'seats'),
  },
  frequency: {
    label: 'Frequency',
    selectedFormatter: (stockAlert: StockAlert) =>
      capitalizeFirstLetter(selectedFrequencyToString(stockAlert)),
  },
};
